import { Box, Container, Flex, Heading } from '@chakra-ui/react';
import { Select } from 'chakra-react-select';
import {
  ArcElement,
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Colors,
  Legend,
  LinearScale,
  Tooltip,
} from 'chart.js';
import { useState } from 'react';
import { GraphCollection } from 'src/components/Dashboard/GraphCollection';
import { reportsApi } from 'src/features/reportsApi';
import { ColorMap } from 'src/themes/Attributes';

ChartJS.register(ArcElement, BarElement, Tooltip, Legend, CategoryScale, LinearScale, Colors);

const { useGetAllGraphCollectionsQuery } = reportsApi;

type CollectionOption = {
  label: string;
  value: string;
};

export const Dashboard = () => {
  const { data } = useGetAllGraphCollectionsQuery({ src: 'web' });

  const collectionOptions = data
    ? Object.keys(data).map((collectionId: any) => ({
        label: data[collectionId].name,
        value: collectionId,
      }))
    : [];

  const [selectedCollection, setSelectedCollection] = useState<CollectionOption | null>(null);

  return (
    <Box width="100%" height="100vh" backgroundColor={ColorMap.white}>
      <Flex direction="column" height="100%">
        <Container p={10} maxW="full">
          <Heading mb={8}>Dashboard</Heading>
          <Box width={{ base: '100%', md: '60%' }}>
            <Select
              size={{ base: 'sm', md: 'md' }}
              id="graph-select"
              name="graph-collections"
              placeholder="Select the graph collection to display..."
              closeMenuOnSelect
              options={collectionOptions}
              value={selectedCollection}
              onChange={(value) => {
                setSelectedCollection(value);
              }}
            />
          </Box>
          {data && selectedCollection ? <GraphCollection collectionId={selectedCollection.value} /> : null}
        </Container>
      </Flex>
    </Box>
  );
};
