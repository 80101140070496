import { ProjectRecord } from '@karya/core';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from 'src/app/Store';
import { apiTags, backendApi } from 'src/features/apiSlice';
import { updateCurrentProject } from 'src/features/projectsSlice';

export function useSelectProject() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleProjectChange = useCallback((project: ProjectRecord) => {
    dispatch(updateCurrentProject(project));

    // Refetch project-dependent data
    const tagsToExclude: (typeof apiTags)[number][] = ['projects', 'boxes', 'current_user', 'server_users'];
    const tagsToInvalidate = apiTags.filter((tag) => !tagsToExclude.includes(tag));
    dispatch(backendApi.util.invalidateTags(tagsToInvalidate));
    navigate(`/projects/${project.id}`);
  }, []);
  return handleProjectChange;
}
