import { NgGenericTask } from '@karya/core';
import { ParameterForm } from '@karya/parameter-specs';
import { FormChangedHook, useForm } from 'src/helpers/parameter-renderer/hooks';

export type ImportSpecForm = { spec: NgGenericTask };

const importSpecForm: ParameterForm<ImportSpecForm> = [
  {
    label: 'Task Specification',
    description:
      'The task specification is a description of the data flow and is used to render the chat based interaction between the user and the karya app',
    parameters: [
      {
        id: 'spec',
        label: 'Import Specification',
        description: 'Import and edit an existing task specification',
        type: 'json_object',
        required: false,
      },
    ],
    required: true,
  },
];

export function useImportSpec(onImport: FormChangedHook<ImportSpecForm>) {
  const importJsonForm = useForm<ImportSpecForm>({
    parameters: importSpecForm,
    formChangedHook: onImport,
  });
  return importJsonForm;
}
