import { DatasetImportType, datasetImportTypeMap } from '@karya/core';
import { ParameterArray, ParameterSection } from '@karya/parameter-specs';
import { useEffect, useState } from 'react';
import { backendApi } from 'src/features/apiSlice';
import { useForm } from 'src/helpers/parameter-renderer/hooks';
import { useFlagEffect } from 'src/hooks/Misc';
import { useApiToastEffect } from 'src/hooks/Toasts';
import { Promise as BBPromise } from 'bluebird';

interface IngestDatapointFormParameters {
  import_type: DatasetImportType;
  tgz_required_option: string | undefined;
  tgz_option: string | undefined;
  file_array: File[] | undefined;
}

// Get import types and the display names
const importTypes: { [key: string]: string } = {};
for (const key in datasetImportTypeMap) {
  importTypes[key] = datasetImportTypeMap[key as DatasetImportType].name;
}

const ingestDatapointFormParameters: ParameterSection<IngestDatapointFormParameters>[] = [
  {
    label: 'Ingest New Datapoints',
    description:
      'Upload the input data file (in JSON format) for the dataset if it exists.\
      If your dataset contains files, package them into .tgz format',
    required: true,
    parameters: [
      {
        id: 'import_type',
        label: 'Import Type',
        description: 'Select import type',
        type: 'enum',
        list: importTypes,
        required: true,
        initial: 'CUSTOM_IMPORT_TYPE',
      },
    ],
  },
];

// Function to generate file input parameters
export function generateFileParamsForm(importTypeState: DatasetImportType | undefined) {
  if (!importTypeState) {
    return [];
  } else {
    const importTypeObj = datasetImportTypeMap[importTypeState as DatasetImportType];
    const filesRequired = importTypeObj.files_required;
    const filesOptional = importTypeObj.files_optional ? importTypeObj.files_optional : [];
    const requiredParams: ParameterArray = getFileParams(filesRequired, true);
    const optionalParams: ParameterArray = getFileParams(filesOptional, false);

    const section: ParameterSection<any> = {
      label: 'Select input file(s)',
      description: '',
      required: true,
      parameters: [...requiredParams, ...optionalParams],
    };

    return [section];
  }
}

function getFileParams(files: string[], required: boolean) {
  const tgzOptionEntries = { tgz_upload: 'Upload tarball', multifiles_upload: 'Upload files separately' };
  const paramId = required ? 'tgz_required_option' : 'tgz_option';
  const params: ParameterArray = files.map((file) =>
    file === 'tgz'
      ? {
          id: paramId,
          label: 'Select option',
          type: 'enum',
          list: tgzOptionEntries,
          required: required,
        }
      : {
          id: file,
          label: `Upload ${file.toUpperCase()} file`,
          type: 'file',
          required: required,
        }
  );
  return params;
}

function getTgzOptionParam(tgzRequiredOptionState: string | undefined, tgzOptionState: string | undefined) {
  let tgzOptionParamSection: ParameterSection<any> | undefined = undefined;
  if (tgzOptionState) {
    tgzOptionParamSection = {
      label: '',
      description: '',
      required: false,
      parameters: [
        tgzOptionState === 'tgz_upload'
          ? {
              id: 'tgz',
              label: 'Upload TGZ file',
              type: 'file',
              required: false,
            }
          : {
              id: 'file_array',
              label: 'Upload files',
              type: 'multi_file',
              showFileNames: false,
              required: false,
            },
      ],
    };
  } else if (tgzRequiredOptionState) {
    tgzOptionParamSection = {
      label: '',
      description: '',
      required: true,
      parameters: [
        tgzRequiredOptionState === 'tgz_upload'
          ? {
              id: 'tgz',
              label: 'Upload TGZ file',
              type: 'file',
              required: true,
            }
          : {
              id: 'file_array',
              label: 'Upload file(s)',
              type: 'multi_file',
              showFileNames: false,
              required: true,
            },
      ],
    };
  }
  return tgzOptionParamSection;
}

export function useDatapointIngestionForm(dataset_id: string) {
  // Ingest datapoints query
  const [ingestTrigger, { isLoading, isSuccess, error }] = backendApi.useIngestDatapointsMutation();
  // Ingest single datapoint from file query
  const [ingestFromFileTrigger, { error: fail }] = backendApi.useIngestDatapointFromFileMutation();

  const [formParameters, setFormParameters] = useState(ingestDatapointFormParameters);
  const [isProgressVisible, setProgressVisibility] = useState(false);
  const [multiFileUploadProgress, setMultiFileUploadProgress] = useState(0);
  const [multiFileUploadSuccess, setMultiFileUploadSuccess] = useState(false);

  // Form
  const importForm = useForm({
    parameters: formParameters,
  });

  // Set form parameters based on import type and tgz option
  useEffect(() => {
    const tgz_required_option = importForm.ctx.form.tgz_required_option;
    const tgz_option = importForm.ctx.form.tgz_option;
    const fileFormParameters = generateFileParamsForm(importForm.ctx.form.import_type);
    const tgzOptionParam = getTgzOptionParam(tgz_required_option, tgz_option);
    if (tgzOptionParam) {
      setFormParameters(ingestDatapointFormParameters.concat(fileFormParameters, tgzOptionParam));
    } else setFormParameters(ingestDatapointFormParameters.concat(fileFormParameters));
  }, [importForm.ctx.form.import_type, importForm.ctx.form.tgz_option, importForm.ctx.form.tgz_required_option]);

  useEffect(() => {
    importForm.ctx.form.tgz_required_option = undefined;
    importForm.ctx.form.tgz_option = undefined;
  }, [importForm.ctx.form.import_type]);

  // Handle ingestion
  const handleDatapointIngestion = () => {
    const { value, error } = importForm.validate();
    if (error || !value) return;

    if (value.file_array) {
      let totalProgress = 0;
      setMultiFileUploadSuccess(false);
      setMultiFileUploadProgress(0);
      setProgressVisibility(true);
      const uploadPromises = BBPromise.map(value.file_array, (file) => {
        const import_type = value.import_type;
        const uploadPromise = ingestFromFileTrigger({ dataset_id, file, import_type });
        uploadPromise.then(() => {
          const fileArrayLength = value.file_array ? value.file_array.length : 0;
          const progress = (1.0 / fileArrayLength) * 100;
          totalProgress += progress;
          setMultiFileUploadProgress(totalProgress);
        });
        return uploadPromise;
      });
      // Wait for all file uploads to be initiated
      uploadPromises.then(() => {
        setMultiFileUploadSuccess(true);
      });
    } else {
      // @ts-ignore
      ingestTrigger({ dataset_id, ...value });
    }
  };

  // Reset form on success
  useFlagEffect(isSuccess, importForm.resetForm);
  useFlagEffect(multiFileUploadSuccess, importForm.resetForm);
  useFlagEffect(fail != undefined, importForm.resetForm);

  useFlagEffect(multiFileUploadSuccess, () => setProgressVisibility(false));

  // Toast
  useApiToastEffect({
    isLoading,
    isSuccess,
    isError: error != undefined,
    successToast: 'Datapoints ingested successfully',
    errorToast: 'Failed to ingest datapoints',
  });
  // Toast
  useApiToastEffect({
    isLoading: isProgressVisible,
    isSuccess: multiFileUploadSuccess,
    isError: fail != undefined,
    successToast: 'Datapoints ingested successfully',
    errorToast: 'Failed to ingest datapoints',
  });

  return {
    ctx: importForm.ctx,
    parameters: formParameters,
    handleDatapointIngestion,
    isIngesting: isLoading || isProgressVisible,
    isIngested: isSuccess,
    ingestionError: error,
    isProgressVisible,
    multiFileUploadProgress,
  };
}
