import { NgGenericTask } from '@karya/core';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { metadataSection } from 'src/components/TaskBuilder/FormParameters/Basic';
import { generateComponentParamSections } from 'src/components/TaskBuilder/FormParameters/Generate';
import { useForm } from 'src/helpers/parameter-renderer/hooks';

export type TaskBuilderState = {
  initialState?: NgGenericTask;
};

export function useTaskBuilder(opts: TaskBuilderState) {
  // @ts-expect-error empty spec is not a valid NgGenericTask
  const [spec, setSpec] = useState<NgGenericTask>(opts.initialState ?? {});
  const [formParameters, setFormParameters] = useState([metadataSection]);

  // All the references in the spec
  const references = useMemo<Array<string>>(() => {
    const components = Object.values(spec);
    const referencesList = components.flatMap((component) => {
      if (component.next.src !== 'CONSTANT') {
        return Object.values(component.next.options);
      } else {
        return component.next.value!;
      }
    });
    const uniqueReferences = Array.from(new Set(referencesList));
    return uniqueReferences;
  }, [spec]);

  // All the dangling references in the spec
  const danglingReferences = useMemo<Array<string>>(() => {
    const availableKeys = new Set(Object.keys(spec));
    return references.filter((referredKey) => !availableKeys.has(referredKey) && referredKey !== null);
  }, [spec]);

  // All the unreffered keys
  const unreferredKeys = useMemo<Array<string>>(() => {
    const availableKeys = Object.keys(spec);
    const referencesSet = new Set(references);
    return availableKeys.filter((key) => !referencesSet.has(key) && key !== 'start');
  }, [spec]);

  /**
   * Delete component with key from the task specification
   * @param key Key of the component to delete
   */
  const deleteComponentWithKey = useCallback(
    (key: string) => {
      const { [key]: _, ...updatedSpec } = spec;
      setSpec(updatedSpec as NgGenericTask);
    },
    [spec]
  );

  /**
   * Add component to task specification
   * @param key Key of the component to delete
   */
  const addComponentToSpec = useCallback(
    (component: NgGenericTask[string]) => {
      if (component.ctype === 'END') {
        component.next = {
          type: 'REF',
          src: 'CONSTANT',
          value: null,
        };
      }
      const updatedSpec: NgGenericTask = {
        ...spec,
        [component.key]: component,
      };
      setSpec(updatedSpec);
      componentForm.resetForm();
    },
    [spec]
  );

  const componentForm = useForm({
    parameters: formParameters,
    onSubmit: addComponentToSpec,
    isKeyPath: true,
  });

  useEffect(() => {
    const updatedFormParameters = generateComponentParamSections(componentForm.ctx.form, spec);
    setFormParameters(updatedFormParameters);
  }, [componentForm.ctx.form]);

  /**
   * Reset form and set the component builder state to start component
   */
  const setBuilderToStart = useCallback(() => {
    componentForm.resetForm();
    componentForm.setFormField('ctype', 'START');
  }, []);

  /**
   * Reset form and set the component builder state to end component
   */
  const setBuilderToEnd = useCallback(() => {
    componentForm.resetForm();
    componentForm.setFormField('ctype', 'END');
  }, []);

  /**
   * Reset form and set the component builder state to end component
   */
  const setBuilderToAccepted = useCallback(() => {
    componentForm.setFormField('name', 'Validation Component');
    componentForm.setFormField(
      'description',
      `Validate the datapoint and set the 'accepted' boolean field in assignment output`
    );
    componentForm.setFormField('ctype', 'USER');

    // HACK: type acrobatics required to remove this
    // @ts-expect-error
    componentForm.setFormField('dtype', 'BOOLEAN');

    // HACK: type acrobatics required to remove this
    // @ts-expect-error
    componentForm.setFormField('selected.key', 'accepted');
  }, []);

  /**
   * Reset form and set the component key to reference
   * @param ref key of the component you want to create
   */
  const setBuilderToRef = useCallback((ref: string) => {
    if (ref === 'end') {
      setBuilderToEnd();
      return;
    } else {
      componentForm.setFormField('key', ref);
    }
  }, []);

  return {
    spec,
    setSpec,
    danglingReferences,
    unreferredKeys,
    componentForm,
    actions: {
      setBuilderToStart,
      setBuilderToEnd,
      setBuilderToAccepted,
      setBuilderToRef,
      deleteComponentWithKey,
    },
  };
}
