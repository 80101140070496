import { Button, Card, Flex, Text, VStack } from '@chakra-ui/react';
import { ColorMap } from 'src/themes/Attributes';
import { WarningIcon, WarningTwoIcon } from '@chakra-ui/icons';
import { useTaskBuilder } from 'src/hooks/TaskBuilder';
import { GenericInputParam, GenericOutputParam, GenericTaskWithoutLoops, TaskParams } from '@karya/core';
import React, { useMemo } from 'react';

export type SpecWarningsProps = {
  ctx: ReturnType<typeof useTaskBuilder>;
  params: Partial<TaskParams>;
};

export const SpecWarnings = React.memo((props: SpecWarningsProps) => {
  const hasStart = !!props.ctx.spec.start;
  const hasEnd = !!props.ctx.spec.end;

  const hasAcceptedParam = useMemo(() => {
    const components = Object.values(props.ctx.spec);
    const hasAcceptedParam = components.some((component) => {
      // TODO: Remove GenericTaskWithoutLoops when loop component specification is updated
      const params = Object.values<GenericInputParam | GenericOutputParam | string | GenericTaskWithoutLoops>(
        // @ts-expect-error ignore the loop component
        component
      );

      let isAcceptedPresent = false;
      params.forEach((param) => {
        if (typeof param !== 'object') {
          // Return if not an object (all params are objects)
          return;
        }
        if (param instanceof Array) {
          // Return if it is a GenericTaskWithoutLoops param (legacy)
          return;
        }
        if ('src' in param) {
          // Return if not an input param
          return;
        }

        if (param.type === 'BOOLEAN' && param.key === 'accepted') {
          isAcceptedPresent = true;
        }
      });
      return isAcceptedPresent;
    });

    return hasAcceptedParam;
  }, [props.ctx.spec]);

  const isAcceptedParamMissing = props.params.isValidationTask && !hasAcceptedParam;

  return (
    <VStack gap="5px" alignItems="stretch">
      {hasStart || <SingleWarning type="NO_START" action={props.ctx.actions.setBuilderToStart} />}
      {hasEnd || <SingleWarning type="NO_END" action={props.ctx.actions.setBuilderToEnd} />}

      {isAcceptedParamMissing && (
        <SingleWarning type="MISSING_ACCEPTED" action={props.ctx.actions.setBuilderToAccepted} />
      )}

      {props.ctx.danglingReferences.map((ref) => (
        <SingleWarning
          type="DANGLING_REFERENCE"
          key={ref}
          reference={ref}
          action={() => props.ctx.actions.setBuilderToRef(ref)}
        />
      ))}

      {props.ctx.unreferredKeys.map((ref) => (
        <SingleWarning
          type="UNREFERENCED_COMPONENT"
          key={ref}
          reference={ref}
          action={() => props.ctx.actions.deleteComponentWithKey(ref)}
        />
      ))}
    </VStack>
  );
});

type SingleWarningProps =
  | {
      type: 'NO_START' | 'NO_END' | 'MISSING_ACCEPTED';
      action: () => void;
    }
  | {
      type: 'DANGLING_REFERENCE' | 'UNREFERENCED_COMPONENT';
      reference: string;
      action: () => void;
    };

function SingleWarning(props: SingleWarningProps) {
  let severity: 'HIGH' | 'LOW';

  if (props.type === 'UNREFERENCED_COMPONENT') {
    severity = 'LOW';
  } else {
    severity = 'HIGH';
  }

  let message: string;
  switch (props.type) {
    case 'NO_START':
      message = 'Start component is required but was not found';
      break;
    case 'NO_END':
      message = 'End component is required but was not found';
      break;
    case 'MISSING_ACCEPTED':
      message = `This is a validation task the task output to contain the key 'accepted'`;
      break;
    case 'DANGLING_REFERENCE':
      message = `A reference to component with key '${props.reference}' found, but '${props.reference}' does not exist`;
      break;
    case 'UNREFERENCED_COMPONENT':
      message = `Component with key '${props.reference}' exists but is not referenced`;
      break;
  }

  let buttonText: string;

  if (props.type === 'UNREFERENCED_COMPONENT') {
    buttonText = 'Delete';
  } else {
    buttonText = 'Create';
  }

  switch (props.type) {
    case 'NO_START':
    case 'NO_END':
  }

  const bgColor = {
    HIGH: ColorMap.crimsonRed,
    LOW: ColorMap.skyBlue,
  }[severity];

  const textColor = {
    HIGH: ColorMap.white,
    LOW: ColorMap.deepSea,
  }[severity];

  const Icon = {
    HIGH: WarningTwoIcon,
    LOW: WarningIcon,
  }[severity];

  return (
    <Card bgColor={bgColor} px="10px" py="5px">
      <Flex alignItems="center" gap="15px">
        <Icon color={textColor} boxSize={5} />
        <Text fontWeight="medium" color={textColor} flex={1}>
          {message}
        </Text>
        <Button onClick={props.action}>{buttonText}</Button>
      </Flex>
    </Card>
  );
}
