import { SelectProps } from '../../../types/InputComponentProps';
import { Select } from '@chakra-ui/react';
import { InputContainer } from './FormInputContainer';
import { ButtonSelect } from './ButtonSelect';
import { useRef } from 'react';

export function SelectInput(props: SelectProps) {
  const { id, description, label, error, isInvalid, isDisabled, isRequired, options, size: _size, ...rest } = props;
  const numKeys = Object.keys(options).length;
  const selectRef = useRef<HTMLSelectElement>(null);
  const isUseButtons = numKeys < 5;
  const handleButtonSelect = (value: string) => {
    if (selectRef.current) {
      const event = new Event('change', { bubbles: true });
      selectRef.current.value = value;
      selectRef.current.dispatchEvent(event);
    }
  };
  return (
    <InputContainer
      id={id}
      description={description}
      label={label}
      error={error}
      isInvalid={isInvalid}
      isRequired={isRequired}
      isDisabled={isDisabled}
    >
      <Select placeholder="Select option" id={id} display={isUseButtons ? 'none' : undefined} ref={selectRef} {...rest}>
        {Object.entries(options).map(([value, label]) => (
          <option key={value} value={value}>
            {label}
          </option>
        ))}
      </Select>
      {isUseButtons && (
        <ButtonSelect
          options={options}
          value={rest.value as keyof typeof options}
          onChange={handleButtonSelect}
          isDisabled={isDisabled}
        />
      )}
    </InputContainer>
  );
}
